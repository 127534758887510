import { ShortPublicationDateTime } from "@/components/PublicationDateTime/components/ShortPublicationDateTime/ShortPublicationDateTime.component";
import { SectionLink } from "@/components/SectionLink/SectionLink.component";
import { Translation } from "@/components/Translation/Translation.component";
import type { Translations } from "@/components/Translation/translation.types";
import type { ZonedDateTime } from "@/ts/date-time/date-time.types";
import clsx from "clsx";
type LinkSectionProps = {
  readonly isRecommendation?: boolean;
  readonly publishDate: ZonedDateTime;
  readonly section: {
    readonly name: string | Translations;
    readonly url?: string | null;
  };
};
export function LinkSection({
  isRecommendation,
  publishDate,
  section: {
    url,
    name
  }
}: LinkSectionProps) {
  if (name === undefined) return null;
  return <div className="hidden divide-x divide-silver lg:mb-1 lg:flex" data-sentry-component="LinkSection" data-sentry-source-file="SectionLinkSection.component.tsx">
      <SectionLink className={clsx("pr-[0.5ch]", isRecommendation && "text-primary")} targetUrl={url} data-sentry-element="SectionLink" data-sentry-source-file="SectionLinkSection.component.tsx">
        <Translation model={name} data-sentry-element="Translation" data-sentry-source-file="SectionLinkSection.component.tsx" />
      </SectionLink>

      {isRecommendation ? <span className="select-none pl-[0.5ch] text-xs uppercase text-dim">
          <Translation model={{
        da: "Fra",
        de: "Von",
        en: "From",
        no: "Fra",
        sv: "Från"
      }} />
          &nbsp;
          <ShortPublicationDateTime zonedDateTime={publishDate} />
        </span> : null}
    </div>;
}