import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { Badge } from "@/components/Badge/Badge.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { Translation } from "@/components/Translation/Translation.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import type { ImageRatio } from "@/components/images/image.types.ts";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import clsx from "clsx";
export type ImageVisibility = "hidden" | "lg:hidden" | "xl:hidden";
export type LeadVisibility = "hidden" | "lg:block" | "xl:block";
type TallFocusArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly trackingId?: null | string;
  readonly size?: "lg" | "md" | "sm";
  readonly imageVisibility?: ImageVisibility;
  readonly leadVisibility?: LeadVisibility;
  readonly ratio?: ImageRatio;
};
export function TallFocusArticleTeaser({
  article,
  className,
  hasImagePriority,
  imageVisibility,
  leadVisibility = "lg:block",
  trackingId,
  ratio = "4:3"
}: TallFocusArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const {
    headline,
    id,
    lead,
    supportingImage,
    targetUrl
  } = article.content;
  const {
    requiresSubscription
  } = article;
  const imageClassName: Record<ImageRatio, string> = {
    "3:2": "aspect-3/2",
    "4:3": "aspect-4/3",
    "16:9": "aspect-16/9"
  };
  const imageMapping: Record<ImageRatio, string> = {
    "16:9": supportingImage?.aspectRatio16By9 || "",
    "4:3": supportingImage?.aspectRatio4By3 || "",
    "3:2": supportingImage?.aspectRatio3By2 || ""
  };
  return <article className={clsx(className, "isolate z-0 flex h-full flex-col")} data-sentry-component="TallFocusArticleTeaser" data-sentry-source-file="TallFocusArticleTeaser.component.tsx">
      {supportingImage !== null ? <div className={clsx("relative overflow-hidden", imageClassName[ratio], imageVisibility)}>
          <ArticleTeaserImage alternativeText={supportingImage.alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={`(max-width: ${tailwindScreenSize.md}) 100vw, 300px`} src={imageMapping[ratio]} targetUrl={targetUrl} />
          <div className="pointer-events-none absolute bottom-0 flex w-full flex-col gap-y-1 bg-gradient-to-b from-transparent to-nero p-2 pt-4 lg:pb-3" />
        </div> : null}

      <div className="grow bg-nero p-2 pb-3 text-white md:px-3 lg:px-1.5">
        <SnowplowLink href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
        articleId: id,
        trackingId: trackingId ?? null,
        url: targetUrl
      }} data-sentry-element="SnowplowLink" data-sentry-source-file="TallFocusArticleTeaser.component.tsx">
          <Badge className={clsx("relative z-10 mb-2", imageVisibility !== "hidden" && supportingImage !== null && "-mt-4", imageVisibility === "lg:hidden" && "lg:mt-0", imageVisibility === "xl:hidden" && "xl:mt-0")} data-sentry-element="Badge" data-sentry-source-file="TallFocusArticleTeaser.component.tsx">
            <Translation da="Fokus" de="Ausgewählt" en="Featured" no="Fokus" sv="I fokus" data-sentry-element="Translation" data-sentry-source-file="TallFocusArticleTeaser.component.tsx" />
          </Badge>

          <ArticleTeaserHeading className="text-3xl font-theme-bold hover:underline lg:text-2xl" title={headline} data-sentry-element="ArticleTeaserHeading" data-sentry-source-file="TallFocusArticleTeaser.component.tsx" />
        </SnowplowLink>

        {lead !== null ? <HtmlContent className={clsx(leadVisibility, "hidden text-base leading-snug text-white lg:mt-2")} html={lead.html} /> : null}

        {requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-white lg:mt-3" /> : null}
      </div>
    </article>;
}