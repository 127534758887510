import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { RelatedArticles } from "@/components/RelatedArticles/RelatedArticles.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SectionLink } from "@/components/SectionLink/SectionLink.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import clsx from "clsx";
import type { LargeArticleTeaserProps } from "../LargeArticleTeaser.component";
import { ImageSection } from "./ImageSection.component";
export function DefaultLargeArticleTeaser({
  article,
  className,
  hasImagePriority,
  imagePosition = "before",
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 90vw, 560px`,
  trackingId
}: LargeArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const {
    content: {
      headline,
      id,
      lead,
      relatedArticles,
      section,
      supportingImage,
      targetUrl
    },
    requiresSubscription
  } = article;
  const hasImage = supportingImage !== null;
  return <article className={clsx(className, "h-full pb-3 text-black lg:pb-4")} data-sentry-component="DefaultLargeArticleTeaser" data-sentry-source-file="DefaultLargeArticleTeaser.component.tsx">
      <div className={clsx("grid auto-rows-auto grid-cols-1 gap-2", imagePosition !== "above" && "lg:grid-cols-12 lg:gap-x-4")}>
        {hasImage ? <ImageSection model={{
        supportingImage,
        hasImagePriority: hasImagePriority ?? false,
        imageSourceSetSize,
        targetUrl,
        imagePosition
      }} /> : null}

        <div className={clsx(hasImage && imagePosition !== "above" && "lg:col-span-6", imagePosition === "after" && "lg:order-1", "col-span-full")}>
          {section !== null ? <SectionLink className="mb-1" targetUrl={section.targetUrl}>
              {section.name}
            </SectionLink> : null}

          <SnowplowLink className="block hover:underline" href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }} data-sentry-element="SnowplowLink" data-sentry-source-file="DefaultLargeArticleTeaser.component.tsx">
            <ArticleTeaserHeading className="text-4xl font-theme-bold lg:text-5xl" title={headline} data-sentry-element="ArticleTeaserHeading" data-sentry-source-file="DefaultLargeArticleTeaser.component.tsx" />
          </SnowplowLink>

          {lead !== null ? <SnowplowLink href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }}>
              <HtmlContent className="mt-2 text-base leading-snug transition-colors duration-150 hover:text-black lg:mt-2" html={lead.html} />
            </SnowplowLink> : null}

          {relatedArticles.length > 0 ? <RelatedArticles className="mt-2 text-charcoal" relatedArticles={relatedArticles} type="bullet" /> : null}

          {requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-primary lg:mt-3" /> : null}
        </div>
      </div>
    </article>;
}