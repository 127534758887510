import { LargeFocusArticleTeaser } from "@/components/ArticleTeaser/FocusArticleTeaser/LargeFocusArticleTeaser.component";
import { RelatedArticles } from "@/components/RelatedArticles/RelatedArticles.component";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { DefaultLargeArticleTeaser } from "./_components/DefaultLargeArticleTeaser.component";
export type LargeArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly imagePosition?: "above" | "after" | "before";
  readonly imageSourceSetSize?: string;
  readonly trackingId?: null | string;
};
export function LargeArticleTeaser({
  article,
  className,
  hasImagePriority,
  imagePosition = "before",
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 90vw, 560px`,
  trackingId
}: LargeArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const isFeature = article.content.isFeature;
  if (isFeature) {
    return <LargeFocusArticleTeaser article={article} hasImagePriority={hasImagePriority} renderRelatedArticles={articles => <RelatedArticles isInverted={true} relatedArticles={articles} type="bullet" />} trackingId={trackingId} />;
  }
  return <DefaultLargeArticleTeaser article={article} className={className} hasImagePriority={hasImagePriority} imagePosition={imagePosition} imageSourceSetSize={imageSourceSetSize} trackingId={trackingId} data-sentry-element="DefaultLargeArticleTeaser" data-sentry-component="LargeArticleTeaser" data-sentry-source-file="LargeArticleTeaser.component.tsx" />;
}