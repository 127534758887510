import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import clsx from "clsx";
type ImageSectionProps = {
  readonly model: {
    readonly supportingImage: ArticleTeaserModel.SupportingImage;
    readonly hasImagePriority: boolean;
    readonly imageSourceSetSize: string;
    readonly targetUrl: string;
    readonly imagePosition: "before" | "after" | "above";
  };
};
export function ImageSection({
  model: {
    supportingImage: {
      alternativeText,
      aspectRatio4By3
    },
    hasImagePriority,
    imageSourceSetSize,
    targetUrl,
    imagePosition
  }
}: ImageSectionProps) {
  return <div className={clsx(imagePosition === "after" && "lg:order-2", imagePosition !== "above" && "lg:col-span-6", "relative col-span-full")} data-sentry-component="ImageSection" data-sentry-source-file="ImageSection.component.tsx">
      <div className="aspect-4/3">
        <ArticleTeaserImage alternativeText={alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={imageSourceSetSize} src={aspectRatio4By3} targetUrl={targetUrl} data-sentry-element="ArticleTeaserImage" data-sentry-source-file="ImageSection.component.tsx" />
      </div>
    </div>;
}