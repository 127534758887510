"use client";

import { ArticleTeaserList } from "@/app/(sites)/watch/_components/lists/ArticleTeaserList.component";
import { LargeArticleTeaser } from "@/app/(sites)/watch/_components/teasers/LargeArticleTeaser/LargeArticleTeaser.component";
import { TallArticleTeaser } from "@/app/(sites)/watch/_components/teasers/TallArticleTeaser/TallArticleTeaser.component";
import { BorderHeading } from "@/components/BorderHeading/BorderHeading.component";
import { Grid } from "@/components/Grid/Grid.component";
import { GridItem } from "@/components/Grid/components/GridItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSnowplowRecommendationInViewTracking } from "@/hooks/tracking/snowplow/use-snowplow-recommendation-in-view-tracking.hook";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import clsx from "clsx";
type FrontPageRightNowProps = {
  readonly className?: string;
  readonly id: null | string;
  readonly teasers: ReadonlyArray<ArticleTeaserModel>;
};
export function FrontPageRightNow({
  className,
  id,
  teasers
}: FrontPageRightNowProps) {
  const numberOfFrontpageTeasers = {
    available: teasers.length,
    maximum: 12,
    minimum: 6
  };
  const {
    ref
  } = useSnowplowRecommendationInViewTracking({
    articleIds: teasers.map(article => article.content.id),
    threshold: 0.3,
    trackingId: id
  });
  const repeatedFrontPageRightNowIndexStart = 3;
  if (numberOfFrontpageTeasers.available < numberOfFrontpageTeasers.minimum) {
    return null;
  }
  return <section className={clsx(className, "space-y-4")} data-k5a-pos="home-now" ref={ref} data-sentry-component="FrontPageRightNow" data-sentry-source-file="FrontpageRightNow.component.tsx">
      <BorderHeading data-sentry-element="BorderHeading" data-sentry-source-file="FrontpageRightNow.component.tsx">
        <Translation da="Forsiden lige nu" de="Die jüngsten FinanzBusiness-Artikel" en="Front page now" no="Forsiden akkurat nå" sv="Framsidan just nu" data-sentry-element="Translation" data-sentry-source-file="FrontpageRightNow.component.tsx" />
      </BorderHeading>
      <Grid className="*:border-b *:border-b-silver [&>*:last-child]:border-b-0" data-sentry-element="Grid" data-sentry-source-file="FrontpageRightNow.component.tsx">
        <GridItem columnSpan="1/2" data-k5a-pos="art1" rowSpan="4/1" data-sentry-element="GridItem" data-sentry-source-file="FrontpageRightNow.component.tsx">
          <LargeArticleTeaser article={teasers[0]} imagePosition="above" imageSourceSetSize={`(max-width: ${tailwindScreenSize.md}) 90vw, 470px`} key={teasers[0].content.id} trackingId={id} data-sentry-element="LargeArticleTeaser" data-sentry-source-file="FrontpageRightNow.component.tsx" />
        </GridItem>
        <GridItem columnSpan="1/4" data-k5a-pos="art2" data-sentry-element="GridItem" data-sentry-source-file="FrontpageRightNow.component.tsx">
          <TallArticleTeaser article={teasers[1]} imageSourceSetSize={`(max-width: ${tailwindScreenSize.md}) 40vw, 220px`} key={teasers[1].content.id} leadVisibility="hidden" trackingId={id} data-sentry-element="TallArticleTeaser" data-sentry-source-file="FrontpageRightNow.component.tsx" />
        </GridItem>
        <GridItem columnSpan="1/4" data-k5a-pos="art3" data-sentry-element="GridItem" data-sentry-source-file="FrontpageRightNow.component.tsx">
          <TallArticleTeaser article={teasers[2]} imageSourceSetSize={`(max-width: ${tailwindScreenSize.md}) 40vw, 220px`} key={teasers[2].content.id} leadVisibility="hidden" trackingId={id} data-sentry-element="TallArticleTeaser" data-sentry-source-file="FrontpageRightNow.component.tsx" />
        </GridItem>
        {teasers.slice(repeatedFrontPageRightNowIndexStart, numberOfFrontpageTeasers.minimum).map((teaser, index) => <GridItem columnSpan="1/2" data-k5a-pos={`art${index + repeatedFrontPageRightNowIndexStart + 1}`} key={teaser.content.id}>
              <TallArticleTeaser article={teaser} imageVisibility="hidden" key={teaser.content.id} leadVisibility="hidden" trackingId={id} />
            </GridItem>)}
        {numberOfFrontpageTeasers.available > numberOfFrontpageTeasers.minimum ? <GridItem columnSpan="full">
            <ArticleTeaserList items={teasers.slice(numberOfFrontpageTeasers.minimum, numberOfFrontpageTeasers.maximum)} positionOffset={numberOfFrontpageTeasers.minimum + 1} />
          </GridItem> : null}
      </Grid>
    </section>;
}