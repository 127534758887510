import { TallFocusArticleTeaser } from "@/components/ArticleTeaser/FocusArticleTeaser/TallFocusArticleTeaser.component";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { clsx } from "clsx";
import { ImageSection } from "./_components/ImageSection.component";
import { LinkSection } from "./_components/SectionLinkSection.component";
type TallArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly imageSourceSetSize?: string;
  readonly imageVisibility?: "hidden";
  readonly leadVisibility?: "hidden" | "lg:block" | "xl:block";
  readonly trackingId?: null | string;
  readonly position?: string;
  readonly isRecommendation?: boolean;
};
export function TallArticleTeaser({
  article,
  className,
  hasImagePriority,
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 40vw, 300px`,
  imageVisibility,
  isRecommendation = false,
  leadVisibility = "lg:block",
  position,
  trackingId
}: TallArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const {
    content: {
      headline,
      id,
      isFeature,
      lead,
      publishDate,
      section,
      supportingImage,
      targetUrl
    },
    requiresSubscription
  } = article;
  const sectionObject = {
    name: isRecommendation ? {
      da: "Fik du læst?",
      de: "Haben Sie gelesen?",
      en: "Did you read?",
      no: "Fikk du lest?",
      sv: "Läste du?"
    } : section?.name,
    url: isRecommendation ? null : section?.targetUrl
  };
  return isFeature ? <TallFocusArticleTeaser article={article} hasImagePriority={hasImagePriority} imageVisibility={imageVisibility} leadVisibility={leadVisibility} trackingId={trackingId} data-sentry-element="TallFocusArticleTeaser" data-sentry-component="TallArticleTeaser" data-sentry-source-file="TallArticleTeaser.component.tsx" /> : <article className={clsx(className, "h-full pb-2 text-black lg:pb-3")} data-k5a-pos={position} data-sentry-component="TallArticleTeaser" data-sentry-source-file="TallArticleTeaser.component.tsx">
      <div className="grid grid-cols-5 gap-x-2 lg:grid-cols-1 lg:gap-x-0 lg:gap-y-2">
        {supportingImage !== null ? <ImageSection model={{
        supportingImage,
        hasImagePriority: hasImagePriority ?? false,
        imageSourceSetSize,
        targetUrl,
        imageVisibility
      }} /> : null}

        <div className="col-span-3 lg:col-span-full">
          {sectionObject.name !== undefined ? <LinkSection isRecommendation={isRecommendation} publishDate={publishDate} section={{
          name: sectionObject.name,
          url: sectionObject.url
        }} /> : null}

          <SnowplowLink className="block hover:underline" href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }} data-sentry-element="SnowplowLink" data-sentry-source-file="TallArticleTeaser.component.tsx">
            <ArticleTeaserHeading className="text-xl font-theme-bold lg:text-2xl" title={headline} data-sentry-element="ArticleTeaserHeading" data-sentry-source-file="TallArticleTeaser.component.tsx" />
          </SnowplowLink>

          {leadVisibility !== "hidden" && lead !== null ? <SnowplowLink className={leadVisibility} href={targetUrl} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }}>
              <HtmlContent className={clsx(leadVisibility, "hidden leading-snug transition-colors duration-150 hover:text-black lg:mt-2")} html={lead.html} />
            </SnowplowLink> : null}

          {requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-primary lg:mt-3" /> : null}
        </div>
      </div>
    </article>;
}