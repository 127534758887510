"use client";

import { TallArticleTeaser } from "@/app/(sites)/watch/_components/teasers/TallArticleTeaser/TallArticleTeaser.component";
import { BorderHeading } from "@/components/BorderHeading/BorderHeading.component";
import { Grid } from "@/components/Grid/Grid.component";
import { GridItem } from "@/components/Grid/components/GridItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSnowplowRecommendationInViewTracking } from "@/hooks/tracking/snowplow/use-snowplow-recommendation-in-view-tracking.hook";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import clsx from "clsx";
type ReadAlsoArticlesProps = {
  readonly className?: string;
  readonly teasers: ReadonlyArray<ArticleTeaserModel>;
  readonly id: null | string;
};
export function ReadAlsoArticles({
  className,
  id,
  teasers
}: ReadAlsoArticlesProps) {
  const numberOfReadAlsoTeasers = {
    available: teasers.length,
    maximum: 6,
    minimum: 3
  };
  const {
    ref
  } = useSnowplowRecommendationInViewTracking({
    articleIds: teasers.map(article => article.content.id),
    trackingId: id
  });
  function displayReadAlsoArticlesBetweenIndices(startIndex: number, endIndex: number) {
    return teasers.slice(startIndex, endIndex).map((teaser, index) => <GridItem className="group" columnSpan="1/3" key={teaser.content.id} position={`art${index + startIndex + 1}`}>
        <TallArticleTeaser article={teaser} key={teaser.content.id} trackingId={id} />
      </GridItem>);
  }
  if (numberOfReadAlsoTeasers.available < numberOfReadAlsoTeasers.minimum) {
    return null;
  }
  return <section className={clsx(className, "space-y-4")} ref={ref} data-sentry-component="ReadAlsoArticles" data-sentry-source-file="ReadAlsoArticles.component.tsx">
      <BorderHeading data-sentry-element="BorderHeading" data-sentry-source-file="ReadAlsoArticles.component.tsx">
        <Translation da="Læs også" de="Lesen Sie auch" en="Further reading" no="Les også" sv="Läs också" data-sentry-element="Translation" data-sentry-source-file="ReadAlsoArticles.component.tsx" />
      </BorderHeading>

      <Grid className="*:border-b *:border-b-silver [&>:last-child]:border-b-0 lg:[&>:nth-last-child(-n+3)]:border-b-0" data-sentry-element="Grid" data-sentry-source-file="ReadAlsoArticles.component.tsx">
        {displayReadAlsoArticlesBetweenIndices(0, numberOfReadAlsoTeasers.minimum)}
        {numberOfReadAlsoTeasers.available >= numberOfReadAlsoTeasers.maximum ? displayReadAlsoArticlesBetweenIndices(numberOfReadAlsoTeasers.minimum, numberOfReadAlsoTeasers.maximum) : null}
      </Grid>
    </section>;
}