import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import clsx from "clsx";
type ImageSectionProps = {
  readonly model: {
    readonly supportingImage: ArticleTeaserModel.SupportingImage;
    readonly hasImagePriority: boolean;
    readonly imageSourceSetSize: string;
    readonly targetUrl: string;
    readonly imageVisibility?: "hidden";
  };
};
export function ImageSection({
  model: {
    supportingImage: {
      alternativeText,
      aspectRatio4By3
    },
    hasImagePriority,
    imageSourceSetSize,
    targetUrl,
    imageVisibility
  }
}: ImageSectionProps) {
  return <div className={clsx(imageVisibility === "hidden" && "lg:hidden", "relative col-span-2 row-span-full aspect-4/3 lg:col-span-full lg:row-span-1")} data-sentry-component="ImageSection" data-sentry-source-file="ImageSection.component.tsx">
      <ArticleTeaserImage alternativeText={alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={imageSourceSetSize} src={aspectRatio4By3} targetUrl={targetUrl} data-sentry-element="ArticleTeaserImage" data-sentry-source-file="ImageSection.component.tsx" />
    </div>;
}